.breaking-news-container {
    position: relative; /* Permette di posizionare elementi all'interno in modo assoluto */
    display: flex; /* Utilizza flexbox per allineare l'etichetta e il contenuto */
    align-items: center; /* Allinea verticalmente gli elementi */
    background: linear-gradient(270deg, #a20095, #270024, #a20095, #270024, #a20095, #270024, #a20095, #270024, #a20095, #270024); /* Gradiente di colori simili al violetto */
    color: #fff;
    padding: 5px 0;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
    background-size: 600% 600%; /* Dimensione del gradiente per l'animazione */
    animation: colorChange 20s ease infinite; /* Animazione del colore */
  }
  
  .breaking-news-label {
    position: absolute; /* Posizionamento assoluto per mantenere l'etichetta fissa */
    font-weight: bold; /* Grassetto per l'etichetta */
    font-size: 1.5rem; /* Dimensione del font per l'etichetta */
    z-index: 10; /* Assicura che l'etichetta sia sopra il contenuto delle notizie */
    background-color: #270024; /* Sfondo semi-trasparente per coprire il contenuto sottostante */
    padding: 5px 10px; /* Padding attorno al testo per migliorare l'aspetto */
  }
  
  .breaking-news-content {
    display: inline-block;
    animation: scroll 2000s linear infinite; /* Scorrimento delle notizie */
    transform: translateX(0); /* Il testo è visibile immediatamente */
    padding-left: 200px; /* Spazio a sinistra del contenuto per evitare sovrapposizione */
  }
  
  .news-item {
    display: inline-block;
    margin-right: 40px; /* Spazio tra le notizie */
  }
  
  .divider {
    margin: 0 20px;
    color: #a20095;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(0); /* Parte visibile immediatamente */
    }
    100% {
      transform: translateX(-100%); /* Scorre fuori dalla schermata a sinistra */
    }
  }
  
  @keyframes colorChange {
    0% {
      background-position: 0% 50%; /* Inizio dell'animazione */
    }
    50% {
      background-position: 100% 50%; /* Metà dell'animazione */
    }
    100% {
      background-position: 0% 50%; /* Fine dell'animazione */
    }
  }
  