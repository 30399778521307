@keyframes pulse-custom {
    0%, 100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
  }
  
  .animate-pulse-custom {
    animation: pulse-custom 1s infinite;
  }
  
  .group:hover .animate-pulse-custom {
    animation: pulse-custom 1s infinite;
    transform: scale(1.1);
    opacity: 1;
  }
  
  .group:hover .animate-pulse-custom {
    -inset: 5px;
  }
  