.news-ticker {
    width: 100%;
    overflow: hidden;
    background-color: #282c34;
    color: white;
    padding: 0;
    font-size: 1.2em;
    display: flex;
    align-items: center;
    z-index: 1000;
  }
  
  .sticky-top {
    position: sticky;
    top: 0;
    left: 0;
    z-index: 1000;
  }
  
  .ticker-content {
    display: inline-block;
    white-space: nowrap;
    animation: scroll 150s linear infinite;
  }
  
  .ticker-item {
    display: inline-block;
    margin-right: 50px;
  }
  
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  